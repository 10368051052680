.fallback-header{
	display: block;
}
.pagination{
	visibility: visible;
}

.fallback-header{
	// max-width: 1240px;
	position: absolute;
	width: 100%;
	//border-bottom: 1px solid #aaa;
	display: block !important;
}

.fallback-header{
	position: fixed;
	top: 0;
	z-index: 1;
}

.fallback-header__top{
	position:relative;
	padding: 2em 0px 1em 0px;
	background: #fff;
}

.search{
	text-align: right;
}

.fallback-header .top-navigation__logo{
	max-width: 150px;
	margin: 0 auto;
	display: block;
	position: relative;
	z-index: 4;
}

.fallback-navigation{
	position: absolute;
	top: 0;
	width: 100%;
	color: #fff;
	//display: none;
}

.fallback-navigation ul{
	padding-left: 2.5rem;
}

.fallback-navigation a{
	color: #fff;
}

.fallback-navigation .toggle-button{
	top: 6rem;
	left: 1rem;
	z-index: 4;
	color: #000;
}

.fallback-navigation__menu{
	position: absolute;
	top: 0;
	width: 30%;
	z-index: 2; 
	background-color: #000;
	display: none;
}

.fallback-navigation:hover .fallback-navigation__menu{
	position: absolute;
	top: 0;
	width: 30%;
	z-index: 2; 
	background-color: #000;
	max-height: 600px;
	overflow: scroll;
	display: block;
}

.fallback-navigation__item:nth-child(1){
		margin-top: 10rem;
}

.fallback-navigation__menu.visible{
	height: auto;
	//overflow: scroll;
}

.fallback-navigation__item, .fallback-navigation__item .nav-link{
	color: #fff;
	font-size: 1rem;
	line-height: 2em;
}

.fallback-navigation__item.bigbold{
	font-size: 1.125rem;
}

.fallback-navigation__affiliates{
	margin-top: 2em;
	line-height: 2em;
}

.fallback-navigation__account-links{
	margin-top: 2em;
}

.fallback-navigation__account-links li{
	display: inline-block;
	padding-right: 1em;
	margin-right: 1em;
	border-right: 1px solid #fff;
	&:last-child{
		border-right: none;
	}
}

.fallback-navigation__social-media-links li{
	display: inline-block;
	margin-left: 1em;
}

.fallback-navigation__social-icon{
	height: 1em;
}

.fallback-navigation__contact-link{
	display: inline-block;
	margin: 0px 0px 0px 2.5em;
}

.pagination{
	display: block !important;
}